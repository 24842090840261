import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';

// import Copyright from '../common/footer/Copyright';
// import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";

import AboutTwo from "../elements/about/AboutTwo";
import AboutThree from "../elements/about/AboutThree";

// import AboutFour from "../elements/about/AboutFour";   // This is the one page private sale form
import Social from "../elements/social/Social";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
// import CountDownTimer from "../elements/countdown/CountDown";

import BrandThree from "../elements/brand/Brand";




const SeoAgency = () => {

    // function scrollToBottom() {
    //     window.scrollTo({
    //       top: document.documentElement.scrollHeight,
    //       behavior: 'smooth'
    //     });
    //   }

    function scrollToBottom() {
        window.open('https://www.pinksale.finance/launchpad/0xf53E42733a564F75C4C3fB5Dac80B88575B91515?chain=BSC', '_blank');
    }
    

    return (
        <>
            <SEO title="Doge Roulette BSC" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Doge <br />Roulette</h1>
                                    <p className="description color-white">Experience unique investing on BSC with our token, offering dividends in a specific set of Doge-related tokens.
                                                                            Enjoy consistent and transparent rewards from the diverse Doge ecosystem with every dividend payout.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" onClick={scrollToBottom}>
                                            Join Presale<i className="icon"></i>
                                        </a>
                                        <br/>
                                       
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/dogelogo.png" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = ""
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                
                <AboutTwo />
                
                <Separator />

                <AboutThree />
                
                <BrandThree />
                                
                <Separator />
                
                {/* <Copyright /> */}
                {/* <Social /> */}
            </main>
        </>
    )
}

export default SeoAgency
