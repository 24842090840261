import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="#">Home</Link>
            </li>
            
            <li><a href="https://t.me/dogeroulette">Telegram</a></li>
            <li><a href="https://twitter.com/DogeRouletteBSC">Twitter</a></li>
            <li><a href="https://bscscan.com/token/0x8E5e4c71E7849bca4d0a3D5031b438bEc847e340">BSCScan</a></li>
            <li className="with-megamenu"><a href="https://dexview.com/bsc/0x8e5e4c71e7849bca4d0a3d5031b438bec847e340">Chart</a>
            </li>
        </ul>
    )
}
export default Nav;
