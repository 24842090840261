import React from 'react';

const BrandList = [
    {
        image: './images/brand/pinksale.png',
        url: 'https://www.pinksale.finance'
    },
    {
        image: './images/brand/dexview.png',
        url: 'https://www.dexview.com'
    },
    // {
    //     image: './images/brand/geckoterminal.png',
    //     url: 'https://www.geckoterminal.com'
    // },
    {
        image: './images/brand/bscscan.png',
        url: 'https://bscscan.com/address/0x8E5e4c71E7849bca4d0a3D5031b438bEc847e340'
    },
    {
        image: './images/brand/livecoinwatch.png',
        url: 'https://www.livecoinwatch.com'
    },

    {
        image: './images/brand/pancake.png',
        url: 'https://www.pancakeswap.finance'
    },

]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={`${data.url}`}><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
