import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What is Doge-Roulette?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Doge-Roulette is a pioneering rewards token offering immediate utility from day one. It combines the excitement of 
                                cryptocurrency with the thrill of online gaming, providing a unique and engaging experience for users.  </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    How can I participate in the private sale?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>To join our private sale on PinkSale, always use the official link provided. To avoid scams, we strongly recommend 
                                verifying the link through our Telegram group. Your security is our top priority!.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    What Games?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Doge-Roulette isn't just about rewards; it's a gaming haven too! Enjoy a variety of on-chain games like Roulette and Slots, 
                                with more thrilling games in the pipeline. Get ready for endless fun and excitement!</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    What are the rewards tokens?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>At launch, Doge-Roulette will feature three popular rewards tokens: DOGE, SHIB, and BabyDoge. These tokens are not just rewards; 
                                they're a part of our commitment to offering diverse and appealing benefits to our users.</Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    How can I stay up to date with Doge-Roulette?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>The best way to stay informed and connected with Doge-Roulette is by joining our vibrant Telegram community. It's the hub for all the 
                                latest updates, discussions, and support. Be a part of the conversation and never miss out!</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

